import React from 'react';
import './whoWeAre.css';


const WhoWeAre = () => {
  return (
    <div className="main">
      <div className="img-container"></div>
      <div className='whoWeAreText'>
        <h2>חוות רימון בכמה מילים</h2>
        <p>
          לאחרונה הסתיים שלב ההקמה של פרויקט ׳חוות רימון׳ בשטח המועצה האזורית בני-
          שמעון, אשר מבוסס על שתי רגליים – חינוך וחקלאות בת-קיימא.<br />
          שמי ניר אמיתי, בן 36 ,חבר קיבוץ להב, בעל תואר שני במדעי המדינה. לפני כשנה וחצי
          השתחררתי משירות של 17 שנים כטייס ומפקד בצה״ל בדרגת רס״ן. טס במילואים באופן
          קבוע.
          מזה מספר שנים בוער בי הצורך לתת מענה לנוער הנמצא במצבי סיכון וזקוק למסגרת, זאת
          בעקבות פיגוע בו נכחתי והשפיע עלי עמוקות, וכתובנה מתהליך התנדבות ארוך שביצעתי
          במסגרת עמותת ׳עלם׳.
          ביחד עם שותפים, צוות חזק ומגובש ובניהולו המקצועי של אושרי שרעבי, אשר מגיע מרקע
          של ניהול מסגרת חירום לנוער בסיכון, יצאנו לדרך.
          במהלך השנה החולפת השגנו שטח, קיבלנו אישורים, הקמנו עמותה, התקשרנו עם גופים
          חזקים בדרום, גייסנו כ-5.2 מיליון ש״ח וקיבלנו הכרה עקרונית ממשרד הרווחה ומקרן
          הביטוח הלאומי לילדים ונוער בסיכון. בימים אלה אנו נערכים להקמת כיתת לימוד בשיתוף
          נציגי משרד החינוך והרווחה
          בדרום, על מנת לאפשר לבני
          הנוער מענה פדגוגי במסגרת
          השהות בחווה.
          ׳חוות רימון׳ הינה מסגרת יום-
          מניעתית , אשר מטרתה לתת
          מענה חינוכי-טיפולי לבני נוער
          ללא מסגרת, המצויים במצבים
          של קושי נפשי ו/או רגשי
          ונמצאים בסיכון לקיום
          התנהגויות הרסניות כגון שימוש נרחב בסמים, אלכוהול, עבריינות ועוד.
          אנו מאמינים כי לכל נער ונערה מגיעה אפשרות בחירה - להיות במקום מיטיב ולא להסתובב
          ברחוב. בני הנוער יקבלו בחווה צרכים בסיסיים כגון מוגנות, הכלה, חיוך, ולאחר מכן
          תעסוקה חינוכית עם סביבת מבוגרים שמהווים דוגמא אישית, שיסייעו להם לפתח את
          תחושת הערך העצמי, תחושת המסוגלות ואת הרצון להשתלב בחיי החברה הבוגרים.<br />
          <b>מטרות הפרויקט:</b><br />
          1 .צמצום התנהגויות סיכוניות בקרב בני נוער.<br />
          2 .הקניית כישורי חיים ופיתוח מסוגלות אישית ותעסוקתית.<br />
          3 .שילוב והתמדה במסגרות חינוכיות/תעסוקתיות כאמצעי ליצירת מוביליות חברתית.<br />
          חוות רימון מייצרת עבור השוהים בה שגרה יומית רציפה ועקבית, סדר יום בו בני הנוער
          מתנסים בעבודה חקלאית, לימודים ותעסוקה, במטרה לייצר עבורם רצף של חוויות הצלחה,
          בונות ומקדמות.
          השקעה בצעירים אלו תאפשר את מיקוד המאמצים של השוהים בחיזוק החוסן האישי,
          זיהוי כוחות, חתירה להשתייכות חברתית והשתלבות מיטבית בקהילה ובחברה כבוגרים
          תורמים ומתפקדים.
          עד כה קטלנו כ-20 בני נוער, כאשר במסגרת הפרויקט ישולבו עד 40 בני נוער בכל שנה, לשהות
          אשר נעה בין חצי שנה לשנתיים בהתאם להתקדמות בתוכנית שנקבעה. תהליך הקליטה
          ממשיך כל העת ביתר שאת.
          בנוסף לכך, אנו רואים את החיבורים המרגשים שנוצרים בין קהילות ויישובי המועצה –
          דתיים וחילוניים וכלל שכנינו באזור. בראייתנו, האדמה מחברת ולא מפרידה.
          אני מזמינים אתכם להיות שותפים אמיתיים בהקמת מסגרת פורצת דרך בדרום, <br />
          <b>המיישמת הלכה למעשה את חזונו של דוד בן-גוריון.</b>
        </p>
      </div>
    </div>
  )
}

export default WhoWeAre